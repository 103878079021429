import React, { useEffect } from 'react';
import {
    makeVar,
    ReactiveVar,
    useLazyQuery,
    useReactiveVar,
} from '@apollo/client';
import { IPageState } from 'graphql/api/page-interfaces';
import { ICustomerReturnsPerPage } from 'graphql/rma/rmaInterfaces';
import {
    ICustomerReturnsInput,
    GET_CUSTOMER_RETURNS,
} from 'graphql/rma/returnsList';
import { useReturnsLoader } from './returns-list-loader';

interface IInitializeReturnsListPageProps {
    children: React.ReactNode;
}

export const defaultReturnsListPageState: IPageState = {
    pageSize: 10,
    currentPage: 0,
    totalCount: 0,
};

export const pageReturnsListStateVar: ReactiveVar<IPageState> = makeVar(defaultReturnsListPageState);
export const pageReturnsListVar: ReactiveVar<ICustomerReturnsPerPage[]|null> =
    makeVar<ICustomerReturnsPerPage[]|null>(null);


export const updatePageState = (newPageState: IPageState): void => {
    pageReturnsListStateVar(newPageState);
};

export const updatePageReturns = (newPageOrders: ICustomerReturnsPerPage[]): void => {
    pageReturnsListVar(newPageOrders);
};

export const InitializeReturnsListPage: React.FC<IInitializeReturnsListPageProps> = (
    {
        children,
    },
) => {
    const { returnsPageLoading, setReturnsPageLoading } = useReturnsLoader();
    const pageReturnsListState: IPageState = useReactiveVar(pageReturnsListStateVar);
    const pageReturnsList: ICustomerReturnsPerPage[]|null = useReactiveVar(pageReturnsListVar);
    const { currentPage, pageSize, totalCount } = pageReturnsListState;

    const [getPageReturns] = useLazyQuery(GET_CUSTOMER_RETURNS, {
        variables: {},
        fetchPolicy: 'no-cache',
        onError: () => {
            updatePageReturns([]);
            updatePageState({
                ...pageReturnsListState,
                totalCount,
            });
        },
        onCompleted: (data): void => {
            const { customer } = data;
            const { returns } = customer;
            const { page_info: pageInfo, total_count: pageTotalCount } = returns;
            const { current_page: pageInfoCurrentPage } = pageInfo;
            const page: number = pageInfoCurrentPage ?? currentPage;
            const totalCount: number = pageTotalCount ?? 0;
            const ordersPerPage: ICustomerReturnsPerPage[] = pageReturnsList ?? [];
            ordersPerPage[page] = returns;
            updatePageReturns(ordersPerPage);
            updatePageState({
                ...pageReturnsListState,
                totalCount,
            });
        },
    });

    const returnsLoaded = (): boolean => Boolean(pageReturnsList && pageReturnsList[currentPage]);

    const loadPageReturnsList = async (): Promise<void> => {
        const returnsVariables: ICustomerReturnsInput = {
            currentPage,
            pageSize,
        };
        await getPageReturns({
            variables: returnsVariables,
        });
    };

    useEffect(() => {
        if (returnsLoaded() || currentPage === 0) {
            setReturnsPageLoading(false);
            return;
        }

        if (!returnsPageLoading) {
            setReturnsPageLoading(true);
        }

        loadPageReturnsList().finally((): void => {
            setReturnsPageLoading(false);
        });
    }, [currentPage]);

    return (<>{children}</>);
};
