import { Navigate } from '@silkpwa/module/router/use-navigate';
import { LocationArgType } from '@silkpwa/module/util/location-to-string';
import { IPageState, TypeUpdatePageState } from 'graphql/api/page-interfaces';
import { getUrl, getQueryParamByName } from 'ui/util/get-query-param-by-name';

const PARAM_PAGE = 'p';

const getCurrentPageFromUrl = (): number => {
    const pageParam: string|null = getQueryParamByName(PARAM_PAGE);
    return pageParam ? parseInt(pageParam, 10) : 0;
};

export const setCurrentPage = (
    pageState: IPageState,
    updatePageState: TypeUpdatePageState,
    pageNumber: number,
    navigate: Navigate,
): void => {
    const url: URL = getUrl();
    url.searchParams.set(PARAM_PAGE, `${pageNumber}`);
    const { pathname, search, hash } = url;
    const newLocation: LocationArgType = {
        pathname,
        search,
        hash,
    };
    const newPageState: IPageState = {
        ...pageState,
        currentPage: pageNumber,
    };
    updatePageState(newPageState);
    navigate(newLocation);
};

export const resolveCurrentPage = (
    pageState: IPageState,
    updatePageState: TypeUpdatePageState,
    navigate: Navigate,
): void => {
    const { currentPage, pageSize, totalCount } = pageState;
    const pageParam: string|null = getQueryParamByName(PARAM_PAGE);
    const pageFromUrl: number = getCurrentPageFromUrl();
    const totalPageCount: number = Math.ceil(totalCount / pageSize);

    /**
     * If `currentPage` is `0` - it is the initial page loading, we have to set up the initial page: `1`.
     */
    if (currentPage === 0) {
        const currentPage: number = pageFromUrl > 0 ? pageFromUrl : 1;
        const newPageState: IPageState = {
            ...pageState,
            currentPage,
        };
        updatePageState(newPageState);
        return;
    }

    /**
     * If `totalCount` is `0` - it is the initial page loading, we have to skip further page validations.
     */
    if (totalCount <= 0) {
        return;
    }

    /**
     * If `totalCount` is greater than`0` - page results are loaded, total count is available. There are two
     * cases to be validated:
     * 1) if we have page param in the url, if it is <= `0` - we have to set up page as `1` (first page);
     * 2) if `currentPage` is > `totalPageCount` - we have to set up page as `totalPageCount` (last page).
     */
    if (pageParam !== null && parseInt(pageParam, 10) < 1) {
        setCurrentPage(pageState, updatePageState, 1, navigate);
    }

    if (currentPage > totalPageCount) {
        setCurrentPage(pageState, updatePageState, totalPageCount, navigate);
    }
};
