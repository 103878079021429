import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ReturnsList } from 'ui/component/account/customer/returns-list';
import { SalesLayout } from '../../layouts/sales-layout';
import {
    salesLayoutConfigVar,
    ISalesLayoutConfig,
} from '../../layouts/sales-layout/sales-layout-state';
import { PageContent } from '../../layouts/components/page-content';
import { InitializeReturnsListPage } from './returns-list-state';
import { LoaderContext } from './returns-list-loader';

const ReturnsListPage: React.FC = () => {
    const [returnsPageLoading, setReturnsPageLoading] = useState(false);
    const config: ISalesLayoutConfig = useReactiveVar(salesLayoutConfigVar);
    const t = usePhraseTranslater();
    const pageTitle = t('Your Returns');

    const returnsListComponent: JSX.Element = (
        <LoaderContext.Provider value={{ returnsPageLoading, setReturnsPageLoading }}>
            <InitializeReturnsListPage>
                <PageContent
                    pageTitle={pageTitle}
                    pageLoading={false}
                >
                    <ReturnsList config={config} />
                </PageContent>
            </InitializeReturnsListPage>
        </LoaderContext.Provider>
    );

    return (
        <SalesLayout
            pageKey="your-returns"
            pageTitle={pageTitle}
            pageContent={returnsListComponent}
            isGuestPage={false}
            loadCountries={false}
            loadCart={false}
            loadOrder={false}
        />
    );
};

export { ReturnsListPage as default };
