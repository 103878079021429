import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Navigate, useNavigate } from '@silkpwa/module/router/use-navigate';
import { IPageState } from 'graphql/api/page-interfaces';
import { ICustomerReturnsPerPage, IReturn } from 'graphql/rma/rmaInterfaces';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import {
    pageReturnsListStateVar,
    pageReturnsListVar,
    updatePageState,
} from 'ui/page/sales-pages/rma-pages/returns-list-page/returns-list-state';
import { useReturnsLoader } from 'ui/page/sales-pages/rma-pages/returns-list-page/returns-list-loader';
import { StandardLoadingImage } from 'ui/component/loading-image';
import { Pagination } from 'ui/component/pagination';
import { OrderReturn } from 'ui/component/rma/order-returns/order-return';
import { setCurrentPage, resolveCurrentPage } from './util/params-resolver';
import styles from './style.css';

interface IReturnsListProps {
    config: ISalesLayoutConfig;
}

export const ReturnsList: React.FC<IReturnsListProps> = ({ config }) => {
    const { returnsPageLoading } = useReturnsLoader();
    const pageReturnsListState: IPageState = useReactiveVar(pageReturnsListStateVar);
    const pageReturnsList: ICustomerReturnsPerPage[]|null = useReactiveVar(pageReturnsListVar);
    const { currentPage, pageSize, totalCount } = pageReturnsListState;

    const t = usePhraseTranslater();
    const navigate: Navigate = useNavigate();

    const onPageChange = (pageNumber: number): void => {
        if (currentPage !== pageNumber) {
            setCurrentPage(pageReturnsListState, updatePageState, pageNumber, navigate);
        }
    };

    const getCurrentPageOrders = (): ICustomerReturnsPerPage|null => (
        pageReturnsList && pageReturnsList[currentPage]
            ? pageReturnsList[currentPage]
            : null
    );

    const currentPageReturnsList: ICustomerReturnsPerPage|null = getCurrentPageOrders();
    const returnsList: IReturn[] = currentPageReturnsList?.items ?? [];
    const hasReturns: Boolean = Boolean(returnsList && returnsList.length > 0);

    useEffect(() => {
        resolveCurrentPage(pageReturnsListState, updatePageState, navigate);
    }, [totalCount]);

    return (
        <>
            <div className={styles.returnsListWrapper}>
                {returnsPageLoading && <StandardLoadingImage />}
                {!returnsPageLoading && (
                    <div className={styles.customerReturnsWrapper}>
                        {hasReturns && (
                            <div className={styles.customerReturns}>
                                <div className={styles.returnsWrapper}>
                                    {hasReturns && returnsList.map(
                                        (orderReturn: IReturn) => (
                                            <OrderReturn config={config} orderReturn={orderReturn} />
                                        ),
                                    )}
                                </div>
                                <Pagination
                                    onPageChange={onPageChange}
                                    totalCount={totalCount}
                                    siblingCount={1}
                                    currentPage={currentPage}
                                    pageSize={pageSize}
                                    className={styles.paginationWrapper}
                                    showPrev={false}
                                    showNext={false}
                                />
                            </div>
                        )}
                        {!hasReturns && (
                            <div className={styles.customerHasNoOrders}>
                                <h3>{t('You have not requested any returns.')}</h3>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};
