/* eslint-disable camelcase */
import { gql, TypedDocumentNode } from '@apollo/client';
import { ICustomerReturnsPerPage } from './rmaInterfaces';
import { RETURN_FIELDS, PAGE_INFO_FIELDS } from '../customer/fragments';

export interface ICustomerReturnsInput {
    pageSize?: number;
    currentPage?: number;
}

export interface ICustomerReturnsOutput {
    customer: {
        returns: ICustomerReturnsPerPage;
    };
}

export const GET_CUSTOMER_RETURNS: TypedDocumentNode<ICustomerReturnsOutput, ICustomerReturnsInput> = gql`
    ${RETURN_FIELDS}
    ${PAGE_INFO_FIELDS}
    query GET_CUSTOMER_RETURNS(
        $pageSize: Int
        $currentPage: Int
    ) {
        customer {
            returns(
                pageSize: $pageSize
                currentPage: $currentPage
            ) {
                items {
                    ...ReturnFields,
                }
                page_info {
                    ...PageInfoFields,
                }
                total_count
            }
        }
    }
`;
