import React, { createContext, useContext } from 'react';

interface IReturnsLoaderContext {
    returnsPageLoading: boolean;
    setReturnsPageLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoaderContext = createContext<IReturnsLoaderContext | undefined>(undefined);

export const useReturnsLoader = (): IReturnsLoaderContext => useContext(LoaderContext) as IReturnsLoaderContext;
