import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import {
    usePagination,
    DOTS,
    TypePaginationRangeArray,
} from './util/use-pagination';
import styles from './style.css';

export interface IPaginationProps {
    onPageChange: (newPageNumber: number) => void;
    totalCount: number;
    pageSize: number;
    siblingCount: number;
    currentPage: number;
    className: string;
    showPrev?: boolean;
    showNext?: boolean;
}

export const Pagination: React.FC<IPaginationProps> = (
    {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        className,
        showPrev = true,
        showNext = true,
    },
) => {
    const paginationRange: TypePaginationRangeArray = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
    });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onPrevious = (event: React.MouseEvent<HTMLButtonElement>|React.KeyboardEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        onPageChange(currentPage - 1);
    };

    const onNext = (event: React.MouseEvent<HTMLButtonElement>|React.KeyboardEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        onPageChange(currentPage + 1);
    };

    const onNumber = (
        event: React.MouseEvent<HTMLButtonElement>|React.KeyboardEvent<HTMLButtonElement>,
        pageNumber: number,
    ): void => {
        event.preventDefault();
        onPageChange(pageNumber);
    };

    const lastPage: number = paginationRange[paginationRange.length - 1];

    return (
        <ul
            className={classes(
                styles.paginationContainer, {
                    [className]: className,
                },
            )}
        >
            {showPrev && (
                <li
                    className={classes(
                        styles.paginationItem, {
                            [styles.disabled]: currentPage === 1,
                        },
                    )}
                >
                    <button
                        type="button"
                        data-test="pagination-btn-prev"
                        className={classes(styles.button, styles.arrow, styles.left)}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => onPrevious(event)}
                        onKeyDown={(event: React.KeyboardEvent<HTMLButtonElement>) => onPrevious(event)}
                    />
                </li>
            )}
            {paginationRange.map((pageNumber: number) => {
                if (pageNumber === DOTS) {
                    return (
                        <li className={classes(styles.paginationItem, styles.dots)}>
                            <button
                                type="button"
                                data-test="pagination-btn-dots"
                                className={classes(styles.button, styles.dotsBtn)}
                            >
                                ...
                            </button>
                        </li>
                    );
                }

                return (
                    <li
                        className={classes(
                            styles.paginationItem, {
                                [styles.selected]: pageNumber === currentPage,
                            },
                        )}
                    >
                        <button
                            type="button"
                            data-test={`pagination-btn-number-${pageNumber}`}
                            className={classes(styles.button, styles.numberBtn)}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => onNumber(event, pageNumber)}
                            onKeyDown={(event: React.KeyboardEvent<HTMLButtonElement>) => onNumber(event, pageNumber)}
                        >
                            {pageNumber}
                        </button>
                    </li>
                );
            })}
            {showNext && (
                <li
                    className={classes(
                        styles.paginationItem, {
                            [styles.disabled]: currentPage === lastPage,
                        },
                    )}
                >
                    <button
                        type="button"
                        data-test="pagination-btn-prev"
                        className={classes(styles.button, styles.arrow, styles.right)}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => onNext(event)}
                        onKeyDown={(event: React.KeyboardEvent<HTMLButtonElement>) => onNext(event)}
                    />
                </li>
            )}
        </ul>
    );
};
